import React from 'react';

import usePagination from './pagination';


function Paginator({ totalBooks, page, itemsPerPage, onPageChange }) {
  const {
    page: currentPage,
    goTo,
    getPageItem,
    size,
  } = usePagination({
    totalItems: totalBooks,
    page: page,
    itemsPerPage: itemsPerPage,
    maxPageItems: 9,
    numbers: true,
    arrows: true,
  });

  React.useEffect(() => {
    goTo(currentPage);
    onPageChange(currentPage);
  }, [currentPage, onPageChange, goTo]);

  return (
    <div id="paginator-cont">
      {[...Array(size)].map((_, i) => {
        const { page, props } = getPageItem(i);

        let disabled = false;
        if (page === "gap") {
            disabled = true;
        } else {
          disabled = props.disabled;
        }
        return (
          <button key={i} {...props} disabled={disabled} className="btn-square">
            {
              (() => {
                switch (page) {
                  case 'gap':
                    return '...';
                  case 'previous':
                    return '<<';
                  case 'next':
                    return '>>';
                  default:
                    return page;
                }
              })()
            }
          </button>
        );
      })}
    </div>
  )
}

export default Paginator;
