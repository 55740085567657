import React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import { gql, useQuery, useApolloClient, useMutation } from '@apollo/client';

import format from 'date-fns/format';

import AsyncSelect from 'react-select/async';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import download from 'downloadjs';

import Ricerca from '../components/Ricerca';
import RigaEditoreAssociato from '../components/RigaEditoreAssociato';


const GET_EDITORE = gql`
  query Editore($id: String!) {
    editore(id: $id) {
      idEditore
      editore
      idMaremagnum
      forzaEsportazione
      note
      editoriAssociati {
        edges {
          node {
            idEditore
            editore
          }
        }
      }
    }
  }`;

const LOAD_OPTIONS = gql`
  query CercaEditore($searchTerm: String!) {
    allEditore(editore_Icontains: $searchTerm) {
      edges {
        node {
          idEditore
          editore
          idMaremagnum
          note
        }
      }
    }
  }`;

const UPDATE_EDITORE = gql`
  mutation UpdateEditore($idEditore: String!, $idMaremagnum: Int, $forzaEsportazione: Boolean!, $note: String, $editoriAssociati: [String]) {
    updateEditore(idEditore: $idEditore, idMaremagnum: $idMaremagnum, forzaEsportazione: $forzaEsportazione, note: $note, editoriAssociati: $editoriAssociati) {
      editore {
        idEditore
        editore
        idMaremagnum
        note
        forzaEsportazione
        editoriAssociati {
          edges {
            node {
              idEditore
              editore
            }
          }
        }
      }
    }
  }`;

const isNumeric = (str) => {
  // https://stackoverflow.com/a/175787/263638
  if (typeof str != "string") {
    return false;
  } else {
    return !isNaN(str) && !isNaN(parseFloat(str));
  }
}

const slugify = (str) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();
  return str;
}

const SchedaEditore = () => {
  const history = useHistory();
  const location = useLocation();

  const searchString = new URLSearchParams(location.search).get('search') || '';
  const [editore, setEditore] = React.useState(null);
  const [daAggiungere, setDaAggiungere] = React.useState(null);
  const [associati, setAssociati] = React.useState([]);
  const [saveDisabled, setSaveDisabled] = React.useState(true);
  const [invalidInput, setInvalidInput] = React.useState(false);
  const [forzaEsportazione, setForzaEsportazione] = React.useState(false);

  const apolloClient = useApolloClient();

  const { id } = useParams();

  const fetchEditori = async (input, cb) => {
    if (input && input.trim().length < 2) {
      return [];
    }

    const res = await apolloClient.query({
      query: LOAD_OPTIONS,
      variables: { searchTerm: input }
    });

    if (res.data && res.data.allEditore && res.data.allEditore.edges) {
      return res.data.allEditore.edges.map((ed) => {
        return {
          node: ed.node,
          value: ed.node.idEditore,
          label: ed.node.editore
        }
      }
      );
    }

    return [];
  };

  const search = (e) => {
    e.preventDefault();
    const value = window.document.getElementById("search_input").value;
    let encoded = encodeURI(value);
    if (encoded !== '') {
      encoded = '?search=' + encoded;
    }
    history.push({
      pathname: '/libri/',
      search: encoded,
    })
  };

  useQuery(GET_EDITORE, {
    variables: { id: id },
    onCompleted: (data) => {
      setEditore(data.editore);
      setAssociati(data.editore.editoriAssociati.edges);
      setForzaEsportazione(data.editore.forzaEsportazione);
    }
  });

  const [updateEditore] = useMutation(UPDATE_EDITORE);

  const back = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  React.useEffect(() => {
    if (daAggiungere !== null) {
      setAssociati(old => [...old, daAggiungere]);
      setSaveDisabled(false);
      setDaAggiungere(null);
    }
  }, [daAggiungere]);

  const eliminaEditoreAssociato = (editore) => {
    var nuoviAssociati = [];
    for (const old_ass of associati) {
      if (old_ass.node.idEditore !== editore.idEditore) {
        nuoviAssociati.push(old_ass);
      }
    }
    setAssociati(nuoviAssociati);
    setSaveDisabled(false);
  };

  const salvaEditore = (e) => {
    e.preventDefault();
    updateEditore({
      variables: {
        idEditore: editore.idEditore,
        idMaremagnum: editore.idMaremagnum,
        forzaEsportazione: forzaEsportazione,
        note: editore.note,
        editoriAssociati: associati.map((ass) => ass.node.idEditore)
      }
    }).then(() => {
      toast.success('Editore salvato correttamente', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }).catch((e) => {
      toast.error('Errore dal server: \n' + e, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  };

  return (
    <>
      <Helmet>
        <title>{editore ? `${editore.idEditore} - ${editore.editore}` : "loading..."}</title>
      </Helmet>

      <Ricerca searchString={searchString} search={search} />

      <div id="back-cont">
        <button onClick={back} className="left btn-back"><i className="far fa-long-arrow-left"></i> indietro</button>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="scheda-libro-cont">
        <div className="scheda-editore">
          <div className="campo-scheda campo-scheda-third">
            <div className="bold mb_15">Codice Editore</div>
            <div>{editore ? editore.idEditore : ""}</div>
          </div>
          <div className="campo-scheda campo-scheda-two-third">
            <div className="bold mb_15">Nome Editore</div>
            <div>{editore ? editore.editore : ""}</div>
          </div>
          <div className={"form-input campo-scheda campo-scheda-half " + (invalidInput ? "invalid" : "")}>
            <span className="label-campi-form">ID dell'editore nel CMS di Mare Magnum</span>
            <input
              placeholder=""
              value={editore ? (editore.idMaremagnum ? editore.idMaremagnum : "") : ""}
              onChange={(e) => {
                e.preventDefault();
                if (isNumeric(e.target.value)) {
                  let value = parseInt(e.target.value);
                  setSaveDisabled(false);
                  setInvalidInput(false);
                  setEditore({ ...editore, idMaremagnum: value });
                } else {
                  if (e.target.value.trim().length === 0) {
                    setSaveDisabled(false);
                    setInvalidInput(false);
                    setEditore({ ...editore, idMaremagnum: null });
                  } else {
                    // Questo caso è un errore, non è un numero e non è una stringa vuota
                    setSaveDisabled(true);
                    setInvalidInput(true);
                  }
                }
              }}
            />
          </div>
          <div className="form-input-textarea campo-scheda-textarea">
            <span className="label-campi-form">Note</span>
            <textarea
              placeholder="Scrivi qui una nota"
              defaultValue={editore ? editore.note : ""}
              onChange={(e) => {
                e.preventDefault();
                setEditore({ ...editore, note: e.target.value });
                setSaveDisabled(false);
              }}
            />
          </div>
          <div className="elenco-editori-associati-cont">
            <div className="bbottom">
              <div className="campo-scheda-third">
                <div className="bold">Codice Editore</div>
              </div>
              <div className="campo-scheda-two-third">
                <div className="bold">Editore Associato</div>
              </div>
            </div>
            {
              associati.map((ass) =>
                <RigaEditoreAssociato
                  key={`editore_ass_key_${ass.node.idEditore}`}
                  editore={ass.node}
                  deleteCallback={eliminaEditoreAssociato}
                />
              )
            }
          </div>
          <div className="form-input-add campo-scheda mt_10">
            <span className="label-campi-form">Aggiungi Codice Editore</span>
            <AsyncSelect
              loadOptions={fetchEditori}
              onChange={(opt) => {
                setDaAggiungere(opt);
              }}
              defaultInputValue=""
              value={daAggiungere}
              isClearable={true}
              menuPlacement="top"
              placeholder="Inizia a digitare e seleziona un editore"
            />
          </div>
          <button
            onClick={salvaEditore}
            disabled={saveDisabled}
            className="right btn-small"><i className="fas fa-save"></i> salva
          </button>
          <p style={{ margin: "5px" }} />
          <button
            className="right btn-small"
            onClick={(e) => {
              e.preventDefault();
              const fetchResponsePromise = fetch(`/export-catalogo/${editore.idEditore}/`)
                .then((response) => {
                  return response.blob();
                });

              toast.promise(fetchResponsePromise,
                {
                  pending: `Attendi un attimo la creazione del catalogo di ${editore.editore}`,
                  success: 'Il catalogo è completo',
                  error: 'Ahem… qualcosa si è rotto…'
                }).then((blob) => {
                  let slug_editore = slugify(editore.editore);
                  let d = format(new Date(), 'yyyy-MM-dd');
                  download(blob, `catalogo di ${slug_editore} al ${d}.tsv`);
                });
            }}
          >
            <i className="fas fa-file-export"></i> esporta catalogo
          </button>
        </div>
      </div>
    </>
  )
}

export default SchedaEditore;
