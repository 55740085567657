import React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

import { Helmet } from 'react-helmet-async';
import { gql, useLazyQuery } from '@apollo/client';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Ricerca from '../components/Ricerca';

import { useConstants } from "../contexts/constants";


const GET_BOOK = gql`
query getBook($id: ID!) {
  book(id: $id) {
    id
    ts
    idDjango
    gtin13
    autori
    curatori
    traduttori
    illustratori
    altreResponsabilita
    primoAutore
    titoloComposto
    editore {
      idEditore
      editore
    }
    dataDiPubblicazione
    anno
    prezzoInEuro
    urlCopertina80Px
    urlCopertina200Px
    urlCopertina500Px
    urlCopertina1000Px
    statoEditoriale
    cancellato
    giacenze {
      disponibilita
      magazzino {
        codiceMagazzino
        fornitore {
          tipoPartecipante
          denominazione
          ragioneSociale
        }
        province
      }
    }
    dataDiPubblicazione
    dataFuoriCatalogo
    pagine
    tipologiaDiProdotto
    altezza
    larghezza
    spessore
    peso
    collana
    abstract
    numeroDiVolumiDelCofanetto
    categoriaThema1 {
      codeValue
      codeDescription
    }
    categoriaThema2 {
      codeValue
      codeDescription
    }
    categoriaThema3 {
      codeValue
      codeDescription
    }
    categoriaThema4 {
      codeValue
      codeDescription
    }
    qualificatoreThema1 {
      codeValue
      codeDescription
    }
    qualificatoreThema2 {
      codeValue
      codeDescription
    }
    qualificatoreThema3 {
      codeValue
      codeDescription
    }
    qualificatoreThema4 {
      codeValue
      codeDescription
    }
    soggetto1
    soggetto2
    soggetto3
    forzaEsportazione
  }
}`;

function SchedaLibro() {
  const {
    STATO_EDITORIALE_CHOICES,
    TIPOLOGIA_DI_PRODOTTO_CHOICES,
    DISPONIBILITA_CHOICES
  } = useConstants();
  const history = useHistory();
  const location = useLocation();

  // const [searchString, setSearchString] = React.useState(new URLSearchParams(location.search).get('search') || '');
  const searchString = new URLSearchParams(location.search).get('search') || '';

  const [book, setBook] = React.useState(null);
  const [copertina, setCopertina] = React.useState(null);
  const [dataDiPubblicazione, setDataDiPubblicazione] = React.useState(null);
  const [dataFuoriCatalogo, setDataFuoriCatalogo] = React.useState(null);
  const [dataCancellazioneEAN, setDataCancellazioneEAN] = React.useState(null);
  const [dimensione, setDimensione] = React.useState('N/D');
  const [peso, setPeso] = React.useState(', N/D');
  const [thema, setThema] = React.useState('');

  const { id } = useParams();

  const search = (e) => {
    e.preventDefault();
    const value = window.document.getElementById("search_input").value;
    let encoded = encodeURI(value);
    if (encoded !== '') {
      encoded = '?search=' + encoded;
    }
    history.push({
      pathname: '/libri/',
      search: encoded,
    })
  };

  const goToPublisherPage = (e) => {
    e.preventDefault();
    let encoded = encodeURI(searchString);
    if (encoded !== '') {
      encoded = '?search=' + encoded;
    }
    history.push({
      pathname: `/editore/${book.editore.idEditore}`,
      search: encoded,
    });
  };

  const autori_names = [
    ['autori', 'Autori'],
    ['curatori', 'Curatori'],
    ['traduttori', 'Traduttori'],
    ['illustratori', 'Illustratori'],
    ['altreResponsabilita', 'Altre Responsabilità'],
  ];

  const [getBook] = useLazyQuery(GET_BOOK, {
    variables: { id: id },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setBook(data.book);

      if (data.book.urlCopertina100Px) {
        setCopertina(data.book.urlCopertina100Px);
      } else if (data.book.urlCopertina500Px) {
        setCopertina(data.book.urlCopertina500Px);
      } else if (data.book.urlCopertina200Px) {
        setCopertina(data.book.urlCopertina200Px);
      } else if (data.book.urlCopertina80Px) {
        setCopertina(data.book.urlCopertina80Px);
      } else {
        setCopertina("/img/libro.png");
      }

      if (data.book.dataDiPubblicazione) {
        let d = format(parse(data.book.dataDiPubblicazione, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
        setDataDiPubblicazione(d);
      }

      if (data.book.dataFuoriCatalogo) {
        let d = format(parse(data.book.dataFuoriCatalogo, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
        setDataFuoriCatalogo(d);
      }

      if (data.book.cancellato) {
        let d = format(parseISO(data.book.ts, new Date()), 'dd/MM/yyyy');
        setDataCancellazioneEAN(d);
      }

      if (data.book.altezza && data.book.larghezza && data.book.spessore) {
        setDimensione(data.book.altezza + 'x' + data.book.larghezza + 'x' + data.book.spessore + ' mm');
      }

      if (data.book.peso) {
        setPeso(`, ${data.book.peso} g`);
      }

      const thema_names = [
        'categoriaThema1',
        'categoriaThema2',
        'categoriaThema3',
        'categoriaThema4',
        'qualificatoreThema1',
        'qualificatoreThema2',
        'qualificatoreThema3',
        'qualificatoreThema4',
      ];

      let thema_tmp = [];
      thema_names.forEach((t) => {
        if (data.book[t]) {
          thema_tmp.push(`<b>${data.book[t].codeValue}</b> - ${data.book[t].codeDescription}`);
        }
      });
      setThema(thema_tmp.join('<br />'));
    },
  });

  React.useEffect(() => {
    getBook();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const back = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  return (
    <>
      <Helmet>
        <title>{book ? `${book.gtin13} - ${book.titoloComposto}` : "loading..."}</title>
      </Helmet>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Ricerca searchString={searchString} search={search} />
      <div id="back-cont">
        <button onClick={back} className="left btn-back"><i className="far fa-long-arrow-left"></i> indietro</button>
      </div>
      <div className="scheda-libro-cont">
        <div className="scheda-libro-left">
          <img alt="copertina" className="copertina-libro" src={copertina}></img>
        </div>
        <div className="scheda-libro-right">
          <div className="campo-scheda campo-scheda-third">
            <div className="bold mb_15">Codice EAN</div>
            <div>{book ? book.gtin13 : ""}</div>
          </div>
          <div className="campo-scheda campo-scheda-two-third">
            <div className="bold mb_15">Stato Editoriale</div>
            <div>
              {/* {book ? STATO_EDITORIALE_CHOICES[book.statoEditoriale] : ""} (
              {book ? dataDiPubblicazione : ""}{book ? dataFuoriCatalogo : ""}) */}

              {book ? (
                book.cancellato ? (
                  "EAN cancellato il " + dataCancellazioneEAN
                ) : (
                  STATO_EDITORIALE_CHOICES[book.statoEditoriale] + (
                    dataDiPubblicazione ? (
                      ". Dal " + dataDiPubblicazione
                    ) : (
                      "."
                    )
                  ) + (
                    dataFuoriCatalogo ? (
                      " al " + dataFuoriCatalogo
                    ) : (
                      ""
                    )
                  )
                )
              ) : ""}
            </div>
          </div>
          <div className="campo-scheda">
            <div className="bold mb_15">Titolo</div>
            <div>{book ? book.titoloComposto : ""}</div>
          </div>
          <div>
            {
              book ? (
                autori_names.map(([name, titolo], idx) => {
                  if (book[name].length > 0) {
                    return (
                      <div key={idx} className="campo-scheda">
                        <div className="bold mb_15">{titolo}</div>
                        <div>{book[name].join('; ')}</div>
                      </div>
                    )
                  } else {
                    return null;
                  }
                })) : (
                <p></p>
              )
            }
          </div>
          <div className="campo-scheda campo-scheda-third">
            <div className="bold mb_15">Codice Editore</div>
            <div>{book ? book.editore.idEditore : ""}</div>
          </div>
          <div className="campo-scheda campo-scheda-two-third">
            <div className="bold mb_15">Editore</div>
            <div>
              {book ? (
                <a href={`/editore/${book.editore.idEditore}`} onClick={goToPublisherPage} className="bold">{book.editore.editore}</a>
              ) : ("")
              }
            </div>
          </div>
          <div className="campo-scheda campo-scheda-quarter-01">
            <div className="bold mb_15">Prezzo</div>
            <div>€ {book ? book.prezzoInEuro.replaceAll('.', ',') : ""}</div>
          </div>
          <div className="campo-scheda campo-scheda-quarter-02">
            <div className="bold mb_15">Pagine e Formato</div>
            <div>
              {book ? book.pagine : ""}
            </div>
          </div>
          <div className="campo-scheda campo-scheda-quarter-03">
            <div className="bold mb_15">Dimensione e Peso</div>
            <div>{dimensione}{peso}</div>
          </div>
          <div className="campo-scheda campo-scheda-quarter-04">
            <div className="bold mb_15">N. Volumi</div>
            <div>{book ? (book.numeroDiVolumiDelCofanetto || 1) : ""}</div>
          </div>
          <div className="campo-scheda">
            <div className="bold mb_15">Collana</div>
            <div>
              {book ? book.collana : ""}
            </div>
          </div>
          <div className="campo-scheda">
            <div className="bold mb_15">Tipo Prodotto</div>
            <div>{book ? TIPOLOGIA_DI_PRODOTTO_CHOICES[book.tipologiaDiProdotto] : ""}</div>
          </div>
          <div className="campo-scheda">
            <div className="bold mb_15">Fornitore</div>
            <div>Messaggerie Libri</div>
          </div>
          <div className="campo-scheda">
            <div className="bold mb_15">Disponibilità a magazzino</div>
            {
              book ? (
                <div>
                  {
                    book.giacenze.map(
                      (giacenza, idx) => {
                        return (
                          <p key={idx}>
                            <b>{DISPONIBILITA_CHOICES[giacenza.disponibilita]}</b>&nbsp;
                            presso&nbsp;
                            {giacenza.magazzino[0].fornitore[0].denominazione} (magazzino:&nbsp;
                            {giacenza.magazzino[0].codiceMagazzino})
                          </p>
                        )
                      }
                    )
                  }
                </div>
              ) : (
                <div />
              )
            }
          </div>
          <div className="campo-scheda">
            <div className="bold mb_15">Qualificatori CCE</div>
            <div>
              {book ? <div dangerouslySetInnerHTML={{ __html: thema }} /> : ""}
            </div>
          </div>
          <div className="campo-scheda">
            <div className="bold mb_15">Soggetti</div>
            <div>
              {book ? (
                book.soggetto1
              ) : ""
              }
            </div>
            <div>
              {book ? (
                book.soggetto2
              ) : ""
              }
            </div>
            <div>
              {book ? (
                book.soggetto3
              ) : ""
              }
            </div>
          </div>
          <div className="campo-scheda">
            <div className="bold mb_15">Sinossi</div>
            <div>{book ? book.abstract : "Sinossi non disponibile"}</div>
          </div>
          {/* <div style={{ marginTop: "10px" }}>
            <label id="container-checkbox" className="container-small">
              <input
                type="checkbox"
                checked={forzaEsportazione}
                onChange={(e) => {
                  setForzaEsportazione(!forzaEsportazione);
                  if (!forzaEsportazione === book.forzaEsportazione) {
                    setSaveDisabled(true);
                  } else {
                    setSaveDisabled(false);
                  }
                }}
              />
              <span className="checkmark-small"></span>
              <p id="container-checkbox-text" className="bold">Ignora disponibilità</p>
            </label>
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();

              updateLibro({
                variables: {
                  id: book.id,
                  idDjango: book.idDjango,
                  forzaEsportazione: forzaEsportazione
                }
              }).then(() => {
                toast.success('Libro salvato correttamente', {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                });
                getBook();
                setSaveDisabled(true);
              }).catch((e) => {
                toast.error('Errore dal server: \n' + e, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });
            }}
            disabled={saveDisabled}
            className="right btn-small"><i className="fas fa-save"></i> salva
          </button> */}
        </div>
      </div>
    </>
  )
}

export default SchedaLibro;
