import Cookies from 'js-cookie';

import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import { Helmet } from 'react-helmet-async';

import { useAuth, AuthStatus } from "../contexts/auth";

import maremagnum_logo from '../maremagnum-logo.svg';


function SignIn(props) {
  const { loggedIn, setLoggedIn, firstURL } = useAuth();

  const [isError, setIsError] = useState(false);

  const tryLogin = (e) => {
    e.preventDefault();

    const userName = window.document.getElementById("email").value;
    const password = window.document.getElementById("password").value;

    const formData = new FormData();

    fetch("/admin/login/?next=/admin/", {
      method: "GET",
      credentials: "same-origin",
      headers: {
        Accept:
          "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
      },
    })
      .then(() => {
        formData.append("username", userName);
        formData.append("password", password);
        formData.append("next", "/admin/");
        fetch("/admin/login/", {
          method: "POST",
          body: formData,
          credentials: "same-origin",
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        })
          .then(() => {
            fetch("/test-login/", {
              method: "POST",
              credentials: "same-origin",
              headers: {
                "X-CSRFToken": Cookies.get("csrftoken"),
              },
            })
              .then(response => {
                if (response.status === 200) {
                  setLoggedIn(AuthStatus.LOGGED_IN);
                } else {
                  setLoggedIn(AuthStatus.LOGGED_OUT);
                  setIsError(true);
                }
              })
              .catch(() => {
                setLoggedIn(AuthStatus.LOGGED_OUT);
              })

          }
          ).catch((error) => {
            console.error("Error:", error);
          })
      });
  };

  if (loggedIn === AuthStatus.LOGGED_IN) {
    return <Redirect to={firstURL.current} />;
  }

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div id="index-cont" className="table">
        <div className="table-cell">
          <div className="relative">
            <div id="login-cont">
              <div id="logo-form"><img alt="Logo Mare Magnum" src={maremagnum_logo}></img></div>
              <form onSubmit={tryLogin}>
                <div id="form-login">
                  <div className="form-input mb_10">
                    <span className="label-campi-form">User</span>
                    <input type="email" required id="email" placeholder="Email" autoFocus />
                  </div>
                  <div className="form-input">
                    <span className="label-campi-form">Password</span>
                    <input type="password" required id="password" placeholder="Password" />
                    {
                      isError ? <p className="login-error-message">Inserisci nome utente e password corretti per un account di staff. Nota che entrambi i campi distinguono maiuscole e minuscole.</p> : null
                    }
                  </div>
                  <button className="btn w_100pe mt_70" onClick={tryLogin}>ACCEDI</button>
                </div>
              </form>
            </div>
            <div className="clear none"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;
