import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import PrivateRoutes from "../components/PrivateRoutes";

/* Pagine pubbliche */
import Page404 from "../pages/Page404";
import Page500 from "../pages/Page500";
import SignIn from "../pages/SignIn";

/* Pagine private */
import ElencoLibri from "../pages/ElencoLibri";
import SchedaLibro from "../pages/SchedaLibro";
import SchedaEditore from "../pages/SchedaEditore";

import { useAuth, AuthStatus } from "../contexts/auth";


const Routes = () => {
  const { loggedIn, firstURL } = useAuth();

  return (
    <Router basename="/">
      <Switch>
        <Route key="1" path="/login" exact component={SignIn} />

        {
          loggedIn === AuthStatus.LOGGED_IN ? (
            <PrivateRoutes key="2">
              <Route
                key="2_1"
                path={`/`}
                exact
                render={() => <Redirect to="/libri/" />}
              />
              <Route
                key="2_2"
                path={`/libri/:page?`}
                component={ElencoLibri}
              />
              <Route
                key="2_3"
                path={`/libro/:id`}
                exact
                component={SchedaLibro}
              />
              <Route
                key="2_4"
                path={`/editore/:id`}
                exact
                component={SchedaEditore}
              />
            </PrivateRoutes>
          ) : (
            loggedIn === AuthStatus.LOGGED_OUT ? (
              <Route
                key="2_5"
                render={({location}) => {
                  firstURL.current = location;
                  return <Redirect to="/login/" />
                }}
              />
            ) : null
          )
        }

        <Route key="3" path="/500" exact component={Page500} />
        <Route key="4" component={Page404} />
      </Switch>
    </Router>
  );
};

export default Routes;
