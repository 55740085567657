import React from 'react';

import { useHistory } from 'react-router-dom';

function RigaLibro({ book, searchString }) {
  const history = useHistory();

  const goToBookPage = (e) => {
    e.preventDefault();
    let encoded = encodeURI(searchString);
    if (encoded !== '') {
      encoded = '?search=' + encoded;
    }
    history.push({
      pathname: `/libro/${book.id}`,
      search: encoded,
    });
  };

  const goToPublisherPage = (e) => {
    e.preventDefault();
    let encoded = encodeURI(searchString);
    if (encoded !== '') {
      encoded = '?search=' + encoded;
    }
    history.push({
      pathname: `/editore/${book.editore.idEditore}`,
      search: encoded,
    });
  }

  let iconaInCommercio = '';
  if (book.cancellato) {
    iconaInCommercio = 'commercio-red';
  } else {
    if (book.statoEditoriale === '04' || book.statoEditoriale === '13') {
      iconaInCommercio = 'commercio-green';
    } else if (book.statoEditoriale === '02' || book.statoEditoriale === '16') {
      iconaInCommercio = 'commercio-orange';
    } else {
      iconaInCommercio = 'commercio-red';
    }
  }

  let iconaDisponibilita = 'magazzino-gray';
  for (const giacenza of book.giacenze) {
    if (giacenza.disponibilita === 'Np') {
      iconaDisponibilita = 'magazzino-gray';
    }
  }
  for (const giacenza of book.giacenze) {
    if (giacenza.disponibilita === 'DN' ||
        giacenza.disponibilita === 'FN') {
      iconaDisponibilita = 'magazzino-red';
    }
  }
  for (const giacenza of book.giacenze) {
    if (giacenza.disponibilita === 'DP' ||
        giacenza.disponibilita === 'FL' ||
        giacenza.disponibilita === 'FM' ||
        giacenza.disponibilita === 'FP') {
      iconaDisponibilita = 'magazzino-orange';
    }
  }
  for (const giacenza of book.giacenze) {
    if (giacenza.disponibilita === 'DS' ||
        giacenza.disponibilita === 'FD') {
      iconaDisponibilita = 'magazzino-green';
    }
  }

  return (
    <div className="elenco-libri-row">
      <div className="elenco-libri-cell cell-01"><pre>{book.gtin13}</pre></div>
      <div className="elenco-libri-cell cell-02">{book.primoAutore}</div>
      <a href={`/libro/${book.id}`} onClick={goToBookPage} className="elenco-libri-cell cell-03 bold">{book.titoloComposto}</a>
      <a href={`/editore/${book.editore.idEditore}`} onClick={goToPublisherPage} className="elenco-libri-cell cell-04 bold">{book.editore.editore}</a>
      <div className="elenco-libri-cell cell-05">{book.anno}</div>
      <div className="elenco-libri-cell cell-06">€ {book.prezzoInEuro.replace('.', ',')}</div>
      <div className="elenco-libri-cell cell-07"><span className={iconaInCommercio}><i className="fas fa-circle"></i></span></div>
      <div className="elenco-libri-cell cell-08"><span className={iconaDisponibilita}><i className="fas fa-check-circle"></i></span></div>
    </div>
  )
}

export default RigaLibro;
