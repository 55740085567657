import React from "react";
import { Redirect } from "react-router-dom";

import { useAuth, AuthStatus } from "../contexts/auth";


function PrivateRoutes({ children }) {
  const { loggedIn } = useAuth();

  // eslint-disable-next-line
  switch (loggedIn) {
    case AuthStatus.CHECKING_LOGIN:
      return <div>Checking login...</div>;
    case AuthStatus.LOGGED_IN:
      return children;
    case AuthStatus.LOGGED_OUT:
      return <Redirect to="/login" />;
  }
}

export default PrivateRoutes;
