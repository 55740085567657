import React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import { gql, useQuery } from '@apollo/client';

import Paginator from '../components/Paginator';
import Ricerca from '../components/Ricerca';
import TabellaRisultati from '../components/TabellaRisultati';
import StyledSpinner from '../components/StyledSpinner';


const GET_BOOKS = gql`
query getBooks($perPage: Int!, $offset: Int!, $titoloFullText: String, $orderBy: String) {
  allBooks(first: $perPage, offset: $offset, titoloFullText: $titoloFullText, orderBy: $orderBy) {
    totalCount
    edgeCount
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
      node {
        id
        gtin13
        autori
        primoAutore
        cancellato
        titoloComposto
        editore {
          idEditore
          editore
        }
        statoEditoriale
        giacenze {
          disponibilita
        }
        dataDiPubblicazione
        anno
        prezzoInEuro
        urlCopertina80Px
        urlCopertina200Px
        urlCopertina500Px
        urlCopertina1000Px
      }
    }
  }
}
`;

const ITEMS_PER_PAGE = 12;

function ElencoLibri(props) {
  const { page: page_param } = useParams(); // eslint-disable-line no-unused-vars
  const history = useHistory();
  const location = useLocation();

  const [searchString, setSearchString] = React.useState(new URLSearchParams(location.search).get('search') || '');

  let page = 1;
  if (page_param !== undefined) {
    page = parseInt(page_param, 10);
  }

  const offset = (page - 1) * ITEMS_PER_PAGE;

  const search = (e) => {
    e.preventDefault();
    const value = window.document.getElementById("search_input").value;
    setSearchString(value);
  };

  const { loading, error, data, refetch } = useQuery(GET_BOOKS, {
    variables: {
      perPage: ITEMS_PER_PAGE,
      offset: offset,
      titoloFullText: searchString !== '' ? searchString : undefined,
      orderBy: searchString === '' ? '-gtin_13' : undefined
    },
    onCompleted: (data) => {
      let encoded = encodeURI(searchString);
      if (encoded !== '') {
        encoded = '?search=' + encoded;
      }
      history.push({
        pathname: location.pathname,
        search: encoded,
      })
    }
  });

  React.useEffect(() => {
    refetch();
  }, [searchString, refetch])

  return (
    <>
      <Helmet>
        <title>Lista dei libri</title>
      </Helmet>

      <div>
        <Ricerca searchString={searchString} search={search} />
        <div id="elenco-libri-cont">
          {
            loading ? (
              <p id="risultato-ricerca-text">Risultato della Ricerca <b>Testo della ricerca</b> n. <b>...</b> selezionati su <b>...</b> totali</p>
            ) : (
              <p id="risultato-ricerca-text">Risultato della Ricerca <b>Testo della ricerca</b> n. <b>{data.allBooks.edgeCount}</b> selezionati su <b>{data.allBooks.totalCount}</b> totali</p>
            )
          }

          {
            loading ? (
              <StyledSpinner loading={loading} size={150} color={"#02569a"} />
            ) : (
              error ? (
                <div>Error!</div>
              ) : (
                <>
                  <TabellaRisultati data={data} searchString={searchString} />

                  {
                    data.allBooks.totalCount !== 0 ? (
                      <Paginator
                        totalBooks={data.allBooks.totalCount}
                        page={page}
                        itemsPerPage={ITEMS_PER_PAGE}
                        onPageChange={(nextPage) => {
                          if (page !== nextPage) {
                            history.push(`/libri/${nextPage}`);
                          }
                        }}
                      />
                    ) : (<p>Nessun libro trovato</p>)
                  }
                </>
              )
            )
          }

        </div>
      </div>
    </>
  );
}

export default ElencoLibri;
