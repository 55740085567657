import React from 'react';
import maremagnum_logo from '../maremagnum-logo.svg';

import { useAuth, AuthStatus } from "../contexts/auth";


function Header() {
  const { setLoggedIn } = useAuth();

  const userClickedLogout = () => {
    setLoggedIn(AuthStatus.LOGGED_OUT);

    fetch("/admin/logout/", {
      method: "GET",
      credentials: "same-origin",
      headers: {
        Accept:
          "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
      },
    });
  };

  return (
    <header>
      <div id="logo-header">
        <a href="/"><img alt="Logo Mare Magnum" src={maremagnum_logo}></img></a>
      </div>
      <nav id="menu-cont">
        {/* <a className="sel"><i className="fas fa-home"></i> home</a>
        <a href="elenco-libri.html"><i className="fas fa-list"></i> lista</a>
        <a><i className="fas fa-search"></i> ricerca</a> */}
        <button onClick={userClickedLogout}><i className="far fa-sign-out-alt"></i></button>
      </nav>
    </header>

  )
}

export default Header;
