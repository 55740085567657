import Cookies from 'js-cookie';

import React, { useState, useEffect, useRef } from "react";

import ApolloClient from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { ApolloLink, concat } from "apollo-link";
import { ApolloProvider } from "@apollo/react-hooks";
import { gql, useQuery } from '@apollo/client';
import { InMemoryCache } from "apollo-cache-inmemory";

import { Helmet, HelmetProvider } from 'react-helmet-async';

import StyledSpinner from './components/StyledSpinner';

import { AuthContext, AuthStatus } from "./contexts/auth";

import { ConstantsContext } from "./contexts/constants";

import Routes from "./routes";

import Header from "./components/Header";
import Footer from "./components/Footer";


const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GRAPHQL_PATH,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
  });

  return forward(operation);
});

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});

const list_to_object = (data) => {
  const obj = {};
  data.forEach(item => {
    obj[item.label] = item.value;
  });
  return obj;
};


function App() {
  const [loggedIn, setLoggedIn] = useState(AuthStatus.CHECKING_LOGIN);
  const firstURL = useRef('');

  const [STATO_EDITORIALE_CHOICES, set_STATO_EDITORIALE_CHOICES] = useState({});
  const { data: d1, loading: l1, error: e1 } = useQuery(gql`{ statoEditorialeChoices { label value } }`, { client, skip: loggedIn !== AuthStatus.LOGGED_IN });
  useEffect(() => { if (loggedIn === AuthStatus.LOGGED_IN && !l1 && !e1) { set_STATO_EDITORIALE_CHOICES(list_to_object(d1.statoEditorialeChoices)); } }, [d1, l1, e1, loggedIn]);

  const [TIPOLOGIA_DI_PRODOTTO_CHOICES, set_TIPOLOGIA_DI_PRODOTTO_CHOICES] = useState({});
  const { data: d2, loading: l2, error: e2 } = useQuery(gql`{ tipologiaDiProdottoChoices { label value } }`, { client, skip: loggedIn !== AuthStatus.LOGGED_IN });
  useEffect(() => { if (loggedIn === AuthStatus.LOGGED_IN && !l2 && !e2) { set_TIPOLOGIA_DI_PRODOTTO_CHOICES(list_to_object(d2.tipologiaDiProdottoChoices)); } }, [d2, l2, e2, loggedIn]);

  const [DISPONIBILITA_CHOICES, set_DISPONIBILITA_CHOICES] = useState({});
  const { data: d3, loading: l3, error: e3 } = useQuery(gql`{ disponibilitaChoices { label value } }`, { client, skip: loggedIn !== AuthStatus.LOGGED_IN });
  useEffect(() => { if (loggedIn === AuthStatus.LOGGED_IN && !l3 && !e3) { set_DISPONIBILITA_CHOICES(list_to_object(d3.disponibilitaChoices)); } }, [d3, l3, e3, loggedIn]);

  useEffect(() => {
    fetch("/test-login/", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
      },
    })
      .then(
        response => {
          if (response.status === 200) {
            setLoggedIn(AuthStatus.LOGGED_IN);
          } else {
            setLoggedIn(AuthStatus.LOGGED_OUT);
          }
        }
      )
      .catch(error => {
        console.error("Error: " + error.message);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let authContext = {
    loggedIn,
    setLoggedIn,
    firstURL
  };

  const constants = {
    STATO_EDITORIALE_CHOICES,
    TIPOLOGIA_DI_PRODOTTO_CHOICES,
    DISPONIBILITA_CHOICES
  };

  return (
    <ApolloProvider client={client}>
      <AuthContext.Provider value={authContext}>
        <ConstantsContext.Provider value={constants}>
          <HelmetProvider>
            <div className="App">
              <Header />

              <main>
                <section>

                  <React.Fragment>
                    <Helmet
                      titleTemplate="%s | Informazioni Editoriali"
                      defaultTitle=""
                    />
                    {
                      loggedIn === AuthStatus.CHECKING_LOGIN ? (
                        <StyledSpinner loading={true} size={150} color={"#02569a"} />
                      ) : (
                        <Routes />
                      )
                    }
                  </React.Fragment>

                </section>
              </main>

              <Footer />
            </div>
          </HelmetProvider>
        </ConstantsContext.Provider>
      </AuthContext.Provider>
    </ApolloProvider >
  );
}

export default App;
