import React from 'react'

import RigaLibro from './RigaLibro';

function TabellaRisultati({ data, searchString }) {
  return (
    <div id="elenco-libri-list">
      <div id="first-row" className="elenco-libri-row">
        <div href="/demo" className="elenco-libri-cell cell-01">EAN <span className="hidden"><i className="far fa-long-arrow-down"></i></span></div>
        <div className="elenco-libri-cell cell-02">AUTORE <span className="hidden"><i className="far fa-long-arrow-down"></i></span></div>
        <div className="elenco-libri-cell cell-03">TITOLO <span className="hidden"><i className="far fa-long-arrow-down"></i></span></div>
        <div className="elenco-libri-cell cell-04">EDITORE <span className="hidden"><i className="far fa-long-arrow-down"></i></span></div>
        <div className="elenco-libri-cell cell-05">ANNO <span className="hidden"><i className="far fa-long-arrow-down"></i></span></div>
        <div className="elenco-libri-cell cell-06">PREZZO <span className="hidden"><i className="far fa-long-arrow-down"></i></span></div>
        <div className="elenco-libri-cell cell-07">COM <span className="hidden"><i className="far fa-long-arrow-down"></i></span></div>
        <div className="elenco-libri-cell cell-08">MAG <span className="hidden"><i className="far fa-long-arrow-down"></i></span></div>
      </div>

      {
        data.allBooks.edges.map(function (book, index) {
          return (
            <RigaLibro
              key={index}
              book={book.node}
              searchString={searchString}
            />
          )
        })
      }
    </div>
  )
}

export default TabellaRisultati
