import React from 'react';

import styled from 'styled-components';
import { PuffLoader } from 'react-spinners';

const Spinner = ({ className, loading, size, color }) => (
  <div className={className}>
    <PuffLoader loading={loading} size={size} color={color} />
  </div>
);

const StyledSpinner = styled(Spinner)`
  position: fixed;
  top: 50%;
  left: 95%;
  transform: translate(-50%, -50%);
`;

export default StyledSpinner;
