import React from 'react';


function Ricerca({ searchString, search }) {
  return (
    <form onSubmit={search}>
      <div id="form-ricerca-cont">
        <div id="form-input-ricerca" className="form-input">
          <span className="label-campi-form">Ricerca</span>
          <input id="search_input" placeholder="Cerca" defaultValue={searchString} />
        </div>
        <button id="btn-ricerca" className="btn"><i className="fas fa-search"></i> ricerca</button>
      </div>
    </form>
  )
}

export default Ricerca;
