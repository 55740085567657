import { createContext, useContext } from "react";

const AuthContext = createContext();

const AuthStatus = {
  CHECKING_LOGIN: "CHECKING_LOGIN",
  LOGGED_IN: "LOGGED_IN",
  LOGGED_OUT: "LOGGED_OUT",
}

function useAuth() {
  return useContext(AuthContext);
}

export {AuthContext, AuthStatus, useAuth};
