import React from 'react';

const RigaEditoreAssociato = ({ editore, deleteCallback }) => {

  const deleteEditore = (e) => {
    e.preventDefault();
    deleteCallback(editore);
  };

  return (
    <div className="bbottom">
      <div className="campo-scheda-third-associati">
        <div>{editore.idEditore}</div>
      </div>
      <div className="campo-scheda-two-third-associati">
        <div>{editore.editore}</div>
      </div>
      <a href="/non-esiste" onClick={deleteEditore} className="inline-block right campo-scheda-trash-associati">
        <i className="fas fa-trash-alt"></i>
      </a>
    </div>
  )
};

export default RigaEditoreAssociato;
